import { lazy } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

const Home = lazy(() => import("./pages/home"));
const CV = lazy(() => import("./pages/cv"));
const NotFound = lazy(() => import("./pages/not-found"));

const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/cv" element={<CV />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;
