import React from "react";
import ReactDOM from "react-dom/client";
import AppRoutes from "./routes";

import "./globals.scss";

const rootContainer = document.getElementById("root");

if (rootContainer) {
  const rootRenderer = ReactDOM.createRoot(rootContainer);
  rootRenderer.render(
    <React.StrictMode>
      <AppRoutes />
    </React.StrictMode>
  );
}
